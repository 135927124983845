import 'bootstrap';
//import $ from ("../../../node_modules/jquery")
//import ("../../../node_modules/jquery-ui/dist/jquery-ui")

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;


$('#search').on('input', function(){
    var text = $(this).val();
    console.log(text)

    var faqElements = $('#Faqaccordionsone .card');
    faqElements.show();
    $('#Faqaccordionsone .card').filter(function() {
        return !$(this).text().includes(text);
    }).hide();
});